@import url( 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.nav-bar {
    z-index: 900;
    cursor: default;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: white;
    border: 1px solid #333;
    box-shadow: 8px 8px 5px #444;
    background-image: linear-gradient(180deg, rgb(99, 99, 99), rgb(66, 66, 66) 40%, rgb(33, 33, 33));
    padding-top: 10px;
}

.menu-icon {
    display: none;
}

.logo {
    position: absolute;
    left: 10px;
    top: 9px;
}

.nav-elements {
    width: 100%;
}

.nav-elements ul {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
}

.nav-elements ul li {
    text-align: right;
    padding-right: 20px;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #fcfcfc;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: #fef7e5;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fef7e5;
}

.swiper {
    width: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #000;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}

.page-template {
    text-align: center;
    background: black;
    color: white;
    padding-top: 70px;
    padding-bottom: 50px;
    padding-left: 100px;
    padding-right: 100px;
}

.welcome-video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.pictures-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 680px) {
    .menu-icon {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 14px;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 56px;
        width: 0;
        height: calc(100vh - 56px);
        transition: all 0.3s ease-in;
        overflow: hidden;
        z-index: 3000;
        background-image: linear-gradient(180deg, rgb(99, 99, 99), rgb(66, 66, 66) 40%, rgb(33, 33, 33));
        margin-right: 0;
    }

    .nav-elements.active {
        width: 100vw;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }

    .page-template {
        padding-left: 5px;
        padding-right: 5px;
    }
}
